<template>
  <component :is="isPage?'div':'q-dialog'" id="product-edit" v-model="isDialogOpen" @hidden="cancel" :persistent="persistent">
    <div class="card m-0 h-100 shadow-sm overflow-hidden" :class="{'dialog-view':!isPage}">
      <div v-if="!isPage" class="bg-light-secondary p-2 d-flex align-items-center flex-wrap gap-2 shadow-sm" style="z-index:10">
        <fa-icon :icon="productData._id?'pen':'plus'" :class="productData._id?'text-primary':'text-success'"/>
        <h4 class="m-0">{{$t(`supply-chain.product.${productData._id?'edit':'add'}`)}}</h4>
        <q-space />
        <q-btn flat padding="5px" color="primary" :to="{name: productData._id?'product-edit':'product-add', params:{productId: productData._id}}">
          <fa-icon icon="expand"/>
        </q-btn>
      </div>
    
      <q-form @submit="clickSubmit" class="h-100 d-flex flex-column">
        <component :is="isPage?'div':'q-scroll-area'" ref="scrollAreaRef" class="h-100" delay='600'
          :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          <div>
            <!-- Is service -->
            <div class="d-flex p-2 gap-2 flex-column flex-sm-row">
              <div class="flex-even text-center px-2 py-1 border rounded-lg d-flex flex-column flex-sm-row gap-3 align-items-center" 
                :class="productData.isInventory?'bg-light-primary border-primary':''" @click.stop="toggleInventory(true)">
                <fa-icon :icon="['fad','box']" size="3x" />
                <div class="text-left">
                  <h5 class="m-0 mb-sm-50 text-capitalize">{{$t('supply-chain.product.inventory._')}}</h5>
                  <small class="d-none d-sm-inline">{{$t('supply-chain.product.inventoryDescription')}}</small>
                </div>
              </div>
              <div class="flex-even text-center px-2 py-1 border rounded-lg d-flex flex-column flex-sm-row gap-3 align-items-center"
                :class="!productData.isInventory?'bg-light-primary border-primary':''" @click.stop="toggleInventory(false)">
                <fa-icon :icon="['fad','wrench']" size="3x" />
                <div class="text-left">
                  <h5 class="m-0 mb-sm-50 text-capitalize">{{$t('supply-chain.product.service._')}}</h5>
                  <small class="d-none d-sm-inline">{{$t('supply-chain.product.serviceDescription')}}</small>
                </div>
              </div>
            </div>
            
            <!-- Dietails -->
            <h5 class="text-primary bg-light-secondary px-2 py-75 m-0 d-flex gap-2 align-items-center">
              <div class="text-capitalize">
                <fa-icon :icon="['fad','info']" fixed-width class="mr-1"/>
                {{$t('form.details')}}
              </div>
            </h5>
            <div class="p-2 flex-even d-flex flex-column gap-2">
              <div class="d-flex gap-2 flex-column flex-sm-row">
                <!-- Name -->
                <q-input type="text" v-if="productData.names.length !== productData.names.filter(item=>Boolean(item.name)).length" outlined hide-bottom-space clearable class="flex-even m-0" 
                  @click.stop.prevent="nameDialogRef.open(productData.names).then(e=>(productData.names=e, updateSlug(e)))" :label="$t('form.name')|capitalize" :rules="[val=>Boolean(val)]">
                  <template #prepend><fa-icon :icon="['far','font']" fixed-width class="text-danger mr-25"/></template>
                </q-input>
                <div v-else class="flex-even d-flex">
                  <q-btn-group outline class="flex-wrap">
                    <q-btn v-for="(item, i) in productData.names" color="primary" :key="item.locale" :label="item.name" @click.stop="nameDialogRef.open(productData.names).then(e=>productData.names=e)">
                      <q-badge rounded color="warning" floating>{{item.locale}}</q-badge>
                    </q-btn>
                  </q-btn-group>
                </div>

                <!-- is active -->
                <q-toggle v-model="productData.isActive" checked-icon="check" color="primary" :label="productData.isActive?$t('form.active'):$t('action.inactive')|capitalize" unchecked-icon="clear" class="flex-even" />
                <!-- taxable -->
                <q-toggle v-model="productData.isTaxable" checked-icon="check" color="primary" :label="$t('form.taxable')|capitalize" unchecked-icon="clear" class="flex-even" />
              </div>

              <div class="d-flex gap-2 flex-wrap">
                <!-- Slug -->
                <q-input type="text" outlined hide-bottom-space v-model="productData.slug" class="flex-even m-0 flex-size-4" :label="$t('form.slug')|capitalize" :rules="[val=>Boolean(val)]">
                  <template #prepend><fa-icon :icon="['far','hashtag']" fixed-width class="text-danger mr-25"/></template>
                </q-input>
                <!-- account -->
                <q-select outlined hide-bottom-space v-model="productData.account" :options="productData.isInventory?inventoryAccountOptions:serviceAccountOptions" :option-label="item=>name(item.names)" option-value="_id" emit-value map-options
                  :loading="loading" :label="$t('finance.account')|capitalize" class="flex-even m-0 flex-size-4" :rules="[val=>Boolean(val)]" popup-content-class="mt-1">
                  <template #prepend><fa-icon :icon="['far','sack-dollar']" fixed-width class="text-danger mr-25"/></template>
                </q-select>
                <!-- Unit -->
                <q-select v-model="productData.unit" hide-bottom-space outlined emit-value map-options new-value-mode="add-unique" :loading="loading" class="flex-even m-0 flex-size-4"
                  :options="$global.units.map(unit=>({label:capitalize($t(`unit.${unit}`)),value:unit}))" :label="$t('form.unit')|capitalize" popup-content-class="mt-1">
                  <template #prepend><fa-icon :icon="['far','cube']" fixed-width class="text-primary mr-25"/></template>
                </q-select>
                <!-- Brand -->
                <q-input type="text" outlined hide-bottom-space v-model="productData.brand" class="flex-even m-0 flex-size-4"
                  :label="$t('form.brand')|capitalize" :rules="[ val=>!val || val.length<=50 || $t('rule.too_long')]">
                  <template #prepend><fa-icon :icon="['far','registered']" fixed-width class="text-primary mr-25"/></template>
                </q-input>
                <!-- tags -->
                <q-select v-model="productData.tags" outlined :label="$t('form.tags')|capitalize" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input 
                  :placeholder="$t('message.enter_add_new')" class="flex-even m-0 flex-size-4">
                  <template #selected-item="scope">
                    <q-chip dense removable class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
                  </template>
                  <template #prepend><fa-icon :icon="['far','tags']" fixed-width class="text-primary mr-25"/></template>
                </q-select>
              </div>
            </div>
            
            <!-- Description -->
            <div>
              <h5 class="text-primary bg-light-secondary px-2 py-75 m-0 d-flex gap-2 align-items-center">
                <div>
                  <fa-icon :icon="['fad','align-left']" fixed-width class="mr-1"/>
                  {{$t('form.description')|capitalize}}
                </div>
              </h5>
              <q-input type="textarea" outlined hide-bottom-space v-model="productData.description" rows="2"
                :label="$t('form.description')|capitalize" class="flex-even m-0 p-2" :rules="[ val=>!val || val.length<=500 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','align-left']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
            </div>

            <!-- variants -->
            <div>
              <h5 class="text-primary bg-light-secondary px-2 py-75 m-0 d-flex gap-2 justify-content-between align-items-center">
                <div>
                  <fa-icon :icon="['fad','box']" fixed-width class="mr-1"/>
                  {{$t('form.variants')|capitalize}}
                </div>
                <q-btn push round dense color="success" :loading="loading" @click.stop="addVariant"><fa-icon icon="plus" /></q-btn>
              </h5>
              <q-list separator class="rounded-lg px-50 py-1">
                <transition-group name="items-transition" class="d-flex gap-1 flex-column">
                  <q-item v-if="!productData.variants || productData.variants.length===0" key="-1">
                    <q-item-section class="bg-light rounded-lg align-items-center"><fa-icon :icon="['fad','cauldron']" size="5x" class="text-secondary m-2"/></q-item-section>
                  </q-item>
                  <q-item class="rounded-lg p-50" clickable v-for="(variant, i) in productData.variants" :key="variant.uid" :class="{'bg-light-warning': !variant._id, 'bg-light-danger': !variant.isActive}"><!-- 一组 -->
                    <q-item-section side  class="d-none d-md-flex"># {{i+1}}</q-item-section>
                    <!-- avatar -->
                    <q-item-section avatar>
                      <Avatar :url="variant.files" :defaultIcon="productData.isInventory?'box':'wrench'" size="lg" />
                    </q-item-section>
                    <!-- name -->
                    <q-item-section class="justify-content-center">
                      <h5>{{variant.names | name}}</h5>
                      <q-item-label class="text-primary">{{variant.sku}}</q-item-label>
                    </q-item-section>
                    <q-item-section class="d-none d-sm-flex">
                      <q-item-label class="text-primary">{{variant.currency}} {{variant.price | price}}</q-item-label>
                      <q-item-label v-if="variant.marketPrice" class="text-strike">{{variant.currency}} {{variant.marketPrice | price}}</q-item-label>
                    </q-item-section>
                    <q-item-section v-if="productData.isInventory" class="d-none d-sm-flex">
                      <q-item-label v-if="variant.weight">{{variant.weight}} <span class="text-warning">kg</span></q-item-label>
                      <q-item-label v-if="variant.volume">{{variant.volume}} <span class="text-warning">CBM</span></q-item-label>
                    </q-item-section>
                    <q-item-section side class="d-flex gap-1 flex-row align-items-center">
                      <q-btn flat color="primary" @click.stop="updateVariant(variant)"><fa-icon fixed-width :icon="['fad','pen']" /></q-btn>
                      <q-btn v-if="productData.variants.length!==1" flat color="danger" @click.stop="deleteVariant(variant)"><fa-icon fixed-width :icon="['fad','trash']" /></q-btn>
                    </q-item-section>
                  </q-item>
                </transition-group>
              </q-list>
            </div>

            <!-- Image -->
            <div>
              <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
                <span class="flex gap-2 align-items-center text-capitalize">
                  <fa-icon :icon="['fad','image']" fixed-width />
                  {{$t('form.image')}}
                </span>
              </h5>
              <FileUpload size="160px" model="product" :modelId="productData._id" :files.sync="productData.files" limit="5" class="p-2"/>
            </div>

            <!-- html -->
            <div>
              <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 justify-content-between align-items-center">
                <span class="d-flex gap-2 align-items-center text-capitalize">
                  <fa-icon :icon="['fad','file-code']" fixed-width />
                  {{$t('form.html')}}
                </span>
                <q-btn round dense color="primary" @click.stop="editorDialogRef.open(productData.html).then(c=>$set(productData,'html',c))"><fa-icon :icon="['fad','pen']"/></q-btn>
              </h5>
              <div v-html="decodeURIComponent(productData.html||'')" style="min-height:5rem" class="inner-shadow rounded-lg overflow-auto m-2 p-50"/>
            </div>
          </div>
        </component>

        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary" style="z-index:10">
          <q-btn push no-caps color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
          <q-btn push no-caps color="warning" :loading="loading" @click.prevent.stop="cancel" :label="capitalize($t('action.cancel'))"/>
        </div>
      </q-form>
      <VariantEdit ref="variantEditRef" :isInventory="productData.isInventory"/>
      <NameDialog ref="nameDialogRef" />
      <EditorDialog ref="editorDialogRef"/>
    </div>
    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </component>
</template>

<script>
import axios from '@axios'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { capitalize, fileUrl, toNumberCeil, toNumber, price, i18n, name } from '@core/utils/filter'
import { Toast, swal, Dialog } from '@core/utils/other'
import { v4 as uuidv4 } from 'uuid'
import useProduct from './useProduct'
import FileUpload from '@core/components/file-upload/FileUpload'
import Avatar from '@core/components/Avatar'

// Dialog
import NameDialog from '@core/components/NameDialog'
import EditorDialog from '@core/components/EditorDialog'
import VariantEdit from '../variant/VariantEdit'

export default {
  name: 'product-edit',
  components: {
    FileUpload, Avatar,
    VariantEdit,
    NameDialog, EditorDialog
  },
  filters:{
    capitalize, price, name
  },
  props:{
    isPage: Boolean,
    persistent: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    // Ref
    const nameDialogRef = ref(null)
    const editorDialogRef = ref(null)
    const variantEditRef = ref(null)
    
    const { route, router } = useRouter()
    const {
      loading,
      productData,
      resetProduct,
      fetchProduct,
    } = useProduct()

    let submit = ()=>{
      loading.value = true
      store.dispatch(supplierData.value._id?'product/updateProduct':'product/addProduct', productData.value).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
        router.go(-1)
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }
    const clickSubmit = () => submit()

    // Page view
    if (props.isPage) {
      resetProduct()
      if (route.value.params.productId) {
        loading.value = true
        fetchProduct(route.value.params.productId, ()=>{
          loading.value = false
          if(productData.value && productData.value.variants && productData.value.variants.length>0) productData.value.variants.forEach(v => v.uid = uuidv4())
        })
      }
    }

    // Dialog view
    const isDialogOpen = ref(false)
    let cancelAction = () => router.go(-1)
    const cancel = ()=>cancelAction()
    const open = async id => {
      resetProduct()
      isDialogOpen.value = true
      if(id) {
        loading.value = true
        await fetchProduct(id, ()=>{
          loading.value = false
          if(productData.value && productData.value.variants && productData.value.variants.length>0) productData.value.variants.forEach(v => v.uid = uuidv4())
        })
      }
      return new Promise(res=>{
        submit = () => {    
          loading.value = true
          store.dispatch(id?'product/updateProduct':'product/addProduct', productData.value).then(() => {
            loading.value = false
            Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
            isDialogOpen.value = false
            res(true)
          }).catch(e =>{
            loading.value = false
            Dialog(false).fire({ icon: 'error', title: e.message })
          })
        }
        cancelAction = ()=>{
          isDialogOpen.value = false
          res(false)
        }
      })
    }

  // scroll
    const scrollAreaRef = ref(null)
    const scrollToBottom = function(){
      scrollAreaRef.value.setScrollPosition(scrollAreaRef.value.getScrollTarget().scrollHeight, 450)
    }

  // Account inventory 列表
    const inventoryAccountOptions = ref([])
    const serviceAccountOptions =ref([])
    axios.get(`/account/list/inventory`).then(response => inventoryAccountOptions.value = response.data.accounts).catch(e=>console.log(e))
    axios.get(`/account/list/expense`).then(response => serviceAccountOptions.value = response.data.accounts).catch(e=>console.log(e))

    const toggleInventory = function(isInventory){
      productData.value.isInventory = isInventory
      productData.value.account = null
      if(isInventory !== true) {
        productData.value.unit = 'pcs'
      }
    }

  // update slug
    const updateSlug = val =>{
      let name_en
      if (val.length > 0) name_en = val.find(item=>item.locale==='en')||val[0]
      if (name_en && name_en.name) productData.value.slug = name_en.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
    }

  // volume
    const updateVolume = (i) => {
      const size = productData.value.variants[i].size
      //cm to CBM
      productData.value.variants[i].volume = toNumberCeil(size[0]*size[1]*size[2]/1000000)
    }

  // Variant
    const addVariant = () => variantEditRef.value.open({productId:productData.value._id}).then(v=>{
      if (v===false) return
      productData.value.variants.push({...JSON.parse(JSON.stringify(v)), uid: uuidv4()})
      scrollToBottom()
    })
    const updateVariant = variant => variantEditRef.value.open({variantId: variant._id}).then(v=>{
      if (v===false) return
      Object.assign(productData.value.variants.find(v=>v.uid === variant.uid), JSON.parse(JSON.stringify(v)))
    })
    const deleteVariant = variant => {
      swal.fire({
        icon: 'question',
        title: i18n('message.if_confirm_delete'),
        confirmButtonText: i18n('action.delete'),
        showCancelButton: true
      }).then(v => {
        if(!v.value || !variant ||!variant.uid) return
        variantEditRef.value.deleteVariant(variant).then(()=>{
          // 移除variant item
          productData.value.variants = productData.value.variants.filter(v => v.uid !== variant.uid)
          Toast.fire({icon: 'success', title: i18n('form.success')})
        }).catch( e=> Dialog(false).fire({icon:'error', title:e.message}))
      })
    }

    return {
      loading, capitalize, fileUrl, toNumber, toNumberCeil, name,

      productData,
      clickSubmit,

      // Dialog
      isDialogOpen,
      open,
      cancel,

      variantEditRef,
      nameDialogRef,
      scrollAreaRef,
      editorDialogRef,

      inventoryAccountOptions,
      serviceAccountOptions,

      toggleInventory,
      updateVolume,
      updateSlug,

      // variant
      addVariant,
      updateVariant,
      deleteVariant
    }
  }
}
</script>

<style lang="scss">
#product-edit {
  .dialog-view {
    max-width: 800px;
    width:100%;
  }
  .price {
    .q-field__control-container {
      flex-wrap: nowrap;
      width: 50px
    }
  }
  .q-textarea {
    .q-field__control{
      padding-right: 2px
    }
  }
  .items-transition-enter-active {
    transition: all .3s ease;
  }
  .items-transition-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .items-transition-enter, .items-transition-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
}
</style>